/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import layoutStyle from "./layout.module.scss"

import Header from '../components/header'
// import Footer from '../components/footer'

const Layout = (props) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <div className={layoutStyle.container}>
      <Helmet>
        <link href='https://use.typekit.net/hgv4ldn.css' rel='stylesheet' />
      </Helmet>
      <div className={layoutStyle.content}>
        <Header />
        <main className={layoutStyle.main}>{props.children}</main>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
