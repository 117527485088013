import React from 'react'
import { Link } from 'gatsby'

import headerStyles from './header.module.scss'


const Header = () => {
    return (
        <header className={headerStyles.container}>
            <nav>
                <ul>
                    <li><Link className={headerStyles.link} activeClassName={headerStyles.active} to='/'>Home</Link></li>
                    <li><Link className={headerStyles.link} activeClassName={headerStyles.active} to='/projects/'>Projects</Link></li>
                    <li><Link className={headerStyles.link} activeClassName={headerStyles.active} to='/contact/'>Contact</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header