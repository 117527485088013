import React from 'react'

import Layout from '../components/layout'
import contactStyles from './contact.module.scss'


const Contact = () => {
    return (
        <Layout>
            <ul className={contactStyles.contactRollUp}>
                <li className="subHeader"><a href="https://www.linkedin.com/in/byron-wong-85964113a/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                <li className="subHeader"><a href="mailto:byronwong16@gmail.com">Email</a></li>
            </ul>
        </Layout>
    );
}

export default Contact